/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {  LISTING_TYPE } from "../../../types";
import {
  getEventsList,
  getItemFromStore,
  showToast,
  getIntrestListFeatured,
  getEventsListMap,
  getSearchList,
  scrollToTop,
  formatNumber,
  debounce,
  scrollToBottom,
} from "../../../utils";
import {
  EventListingType,
  FILTER_DEFAULT_VALUES,
  ResponsiveEventListingType,
} from "../../../utils/Constants";
import ReactPaginate from "react-paginate";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../../../global/Button";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import FilterPopup from "./FilterPopup";
import EventsMapContainer from "./EventsMapContainer";
import moment from "moment";
import endpoints from "../../../utils/endpoints";
import {
  makeDeleteCall,
  makePostCall,
} from "../../../utils/Requests";
import dayjs from "dayjs";
import HowItWorks from "../HowItWorksSection";
import EventImageCarousel from "./EventImageCarousel";
import MultipleItems from "./MultipleItems";
import ShareEvents from "./ShareEvents";

const Events2 = () => {
  const ref = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [event, setEvent] = useState<any>([]);
  const [eventMap, setEventMap] = useState<any>([]);
  const [showShare, setShowShare] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [initialCount, setInitialCount] = useState(0);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [currentLat, setCurrentLat] = useState<any>(null);
  const [currentLng, setCurrentLng] = useState<any>(null);
  const [distance, setDistance] = useState<any>(5000);
  const [isLocationAccessible, setIslocationAccessible] = useState<any>(null);
  const [showMap, setShowMap] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [limit, setLimit] = useState(16);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [desktopInterestList, setDesktopInterestList] = useState([]);
  const [interests, setInterests] = useState([]);
  const [search, setSearch] = useState("");
  const [address, setAddress] = useState("");
  const [extraLoader, setExtraLoader] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [applyCount, setApplyCount] = useState(0);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [eventType, setEventType] = useState("P");
  const [activeTab, setActiveTab] = useState("tab1");
  const [searchEvents, setSearchEvents] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [suggstionmodel, setsuggstionmodel] = useState(false);
  const [loadingtext, setLoadingtext] = useState(false); // Add loading state
  const [isExpanded, setIsExpanded] = useState(false);
  const [eventFilterParams, setEventFilterParams] = useState(FILTER_DEFAULT_VALUES)
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchValue(search);
    }, 1500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [search, 1500]);

  const [listingType, setListingType] = useState(
    location.search === "?type=C"
      ? LISTING_TYPE.C
      : location.search === "?type=C"
      ? LISTING_TYPE.C
      : LISTING_TYPE.S
  );

  const handleNotShowShare = () => {
    setShowShare(false);
  };

  
  function useOutsideAlerter(ref: React.RefObject<HTMLElement>, handleClearClick: () => void, setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>) {
    // Memoize the function to avoid creating a new instance on each render
    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setIsExpanded(false);
          handleClearClick();
        }
      },
      [ref, setIsExpanded, handleClearClick]
    );
  
    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      // Cleanup the event listener on unmount
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);
  }
 
  const handlePageClick = (event) => {
    scrollToTop();
    let page = event.selected + 1;
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
  };


  const getListByLocation = (time_id) => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(time_id);
          setCurrentLat(position.coords.latitude);
          setCurrentLng(position.coords.longitude);
          setIslocationAccessible(true);
        },
        (err) => {
          setIslocationAccessible(false);
          showToast(
            "Kindly enable your location to enhance your app experience, however we still show you the events.",
            "error"
          );
        },
        {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      setIslocationAccessible(false);
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
  };
  const getListWithoutLocation = () => {
    setIsLoading(true);
    const {startDate , endDate , minPrice, maxPrice, minAge, maxAge, gender, chipType } = eventFilterParams;
    let params = {
      listing_type: listingType,
      sort: chipType,
      distance_filter: false,
      limit: limit,
      offset: offset,
      listing_event_type: eventType,
      ...(minPrice !== "" && { min_price: minPrice }),
      ...(maxPrice && { max_price: maxPrice }),
      ...(gender && { gender }),
      ...(debouncedSearchValue && { listing_search: debouncedSearchValue }),
      ...(interests.length > 0 && { interests: interests.toString() })
    };
    if (
      listingType !== LISTING_TYPE.C &&
      currentLng &&
      currentLat &&
      chipType === "closest"
    ) {
      params["dist"] = distance;
      params["point"] = `${currentLng},${currentLat}`;
    }

    if (long && lat) {
      params["dist"] = distance;
      params["point"] = `${long},${lat}`;
    }
    if (minAge) {
      params["age"] = minAge + "," + maxAge;
    }
    
    if (startDate && endDate) {
      params["dates"] =
        dayjs(startDate).format("YYYY-MM-DD") +
        "," +
        dayjs(endDate).format("YYYY-MM-DD");
      //dates: moment(startDate).format("YYYY-MM-DD") + "," + moment(endDate).format("YYYY-MM-DD"),
    }

    if (showMap) {
      let temp_params = { ...params };
      temp_params["distance_filter"] = true;
      getEventsListMap(
        temp_params,
        setEventMap,
        setNextUrl,
        setIsLoading,
        setPageCount
      );
    } else {
      if (isLocationAccessible === true && currentLng && currentLat) {
        getEventsList(params, setEvent, setNextUrl, setIsLoading, setPageCount);
      } else {
        getEventsList(params, setEvent, setNextUrl, setIsLoading, setPageCount);
        // getListByLocation();
      }
    }
  };

  useEffect(() => {
    if (initialCount !== 0) {
      getListWithoutLocation();
    } else if (initialCount === 0) {
      let time_id = setTimeout(() => {
        getListWithoutLocation();
      }, 3000);
      getListByLocation(time_id);
      setInitialCount(1);
    }
  }, [
    eventFilterParams?.chipType,
    distance,
    currentLat,
    currentLng,
    isLocationAccessible,
    listingType,
    offset,
    interests,
    // debouncedSearchValue,
    applyCount,
    showMap,
    eventType,
  ]);

  useEffect(() => {
    getIntrestListFeatured(
      {
        featured: true,
        want_paginated: false,
      },
      setDesktopInterestList
    );
  }, []);

  const { isMobile } = useCheckDeviceScreen();

  const handleClearClick = ( ) => {
    setsuggstionmodel(false);
    setSearch("");
    setSearchEvents([]);
    setActiveTab("tab1");
  };

  const handleClearClickNew = ( ) => {
    setsuggstionmodel(false);
    setSearch("");
    setActiveTab("tab1");
  };

  useOutsideAlerter(ref, handleClearClick, setIsExpanded);
  useOutsideAlerter(searchRef, handleClearClickNew, setIsExpanded);

  useEffect(() => {
    if (!search) {
      setsuggstionmodel(false);
      setLoadingtext(false);
      setSearchEvents([]);
      setSearchUsers([]);
    } else {
      setLoadingtext(true);
      getSearchList(
        {
          search: search,
          tab: activeTab === "tab1" ? "Events" : "Users",
        },
        (events) => {
          setSearchEvents(events);
          setLoadingtext(false);
        },
        (users) => {
          setSearchUsers(users);
          setLoadingtext(false);
        }
      );

      setsuggstionmodel(true);
    }
  }, [search, activeTab]);

  const handleLike = async (id) => {
    const apiPayload = {
      listing: id,
    };
    makePostCall({ url: endpoints.favourite_listing, apiPayload })
      .then((res) => {
        setEvent((prevEvents) =>
          prevEvents.map((event) =>
            event.id === id ? { ...event, isFavourite: true } : event
          )
        );
        showToast(res.status.message, "success");
        setExtraLoader(false);
      })
      .catch((error) => {
        const response = error?.response;
        if (
          response?.status == 400 &&
          response?.data?.errors?.nonFieldErrors?.length == 1
        ) {
          if (
            response.data.errors.nonFieldErrors[0] ===
            "The fields listing, marked_by must make a unique set."
          ) {
            showToast("You already marked favourite.", "success");
          }
        } else {
          showToast(error.message, "error");
        }
        setExtraLoader(false);
      });
  };

  const handleDislike = (id) => {
    const apiPayload = {
      listing: id,
    };
    makeDeleteCall({
      url: endpoints.favourite_listing,
      apiPayload,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        setEvent((prevEvents) =>
          prevEvents.map((event) =>
            event.id === id ? { ...event, isFavourite: false } : event
          )
        );
        showToast(res.status.message, "success");
        setExtraLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setExtraLoader(false);
      });
  };


  const debouncedToggleFavourite = useCallback(
    debounce((item: any) => {
      const isAuthenticated = getItemFromStore("access_token");

      if (isAuthenticated) {
        if (item.isFavourite) {
          handleDislike(item.id);
        } else {
          handleLike(item.id);
        }
      } else {
        navigate(`/login`);
      }
    }, 200), // Adjust debounce delay as needed
    [handleDislike, handleLike, navigate]
  );

  return (
    <>
      <HowItWorks />
      {showFilter && (
        <FilterPopup
          isLocationAvailable={currentLng}
          setCurrentPage={setCurrentPage}
          setAddress={setAddress}
          setLat={setLat}
          setLong={setLong}
          address={address}
          setApplyCount={() => {
            let temp_apply = applyCount;
            setApplyCount(temp_apply + 1);
          }}
          setInterests={setInterests}
          show={showFilter}
          onHide={() => setShowFilter(false)}
          setIsFilterActive={setIsFilterActive}
          eventFilterParams={eventFilterParams}
          setEventFilterParams={setEventFilterParams}
        />
      )}
      <div
        style={{
          paddingBottom:
            !showMap && Math.ceil(pageCount / limit) > 1 ? "0px" : "20px",
        }}
        className={`events-page background-grey ${suggstionmodel ? "" : ""}`}
      >
        <div className="selected-listing-type-container d-none d-md-flex">
          <div className="selected-listing-type-wrapper">
            <div className="listing-type-left">
              {EventListingType?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setIsLoading(true);
                      setOffset(0);
                      setCurrentPage(0);
                      setListingType(item.type);
                    }}
                    style={{ pointerEvents: isLoading ? "none" : "auto" }}
                    className={`selected-type ${
                      listingType === item.type ? "active" : ""
                    }`}
                  >
                    <img
                      src={
                        listingType === item.type
                          ? item.inactiveImg
                          : item.activeImg
                      }
                      alt=""
                    />
                    <img src={item.inactiveImg} alt="" />
                    <p className="mb-0">{item.title}</p>
                  </div>
                );
              })}
            </div>
            <div
              ref={searchRef}
              className="listing-type-right-content danny-ref "
              style={{
                width: suggstionmodel ? "auto" : "382px",
                height: "48px",
              }}
            >
              <div className="listing-type-right">
                <img src="static/Search.svg" alt="" />
                <input
                  className="seracinput"
                  placeholder="Search for any Event or Individual"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "italic",
                    fontSize: "12px",
                  }}
                  value={search}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && search) {
                      let activeTabVar =
                        activeTab === "tab1" ? "Events" : "Users";
                      navigate(
                        "/search?search=" + search + "&tab=" + activeTabVar
                      );
                    }
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                {search && (
                  <span onClick={handleClearClick} className="clearstyle">
                    Clear
                  </span>
                )}
              </div>
              {suggstionmodel && (
                <div className="automodel">
                  {/* Suggestion model */}
                  <div className="Tab-section">
                    <div className="tabcontent">
                      <div
                        style={{
                          flexBasis: 0,
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        <button
                          className={`eventsstyle ${
                            activeTab === "tab1" ? "active" : ""
                          }`}
                          onClick={() => setActiveTab("tab1")}
                        >
                          Event
                        </button>
                        <hr
                          className={`eventbottom ${
                            activeTab === "tab1" ? "active" : ""
                          }`}
                        />
                      </div>
                      <div
                        style={{
                          flexBasis: 0,
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        <button
                          className={`Peopleeventstyle ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                          onClick={() => setActiveTab("tab2")}
                        >
                          People
                        </button>
                        <hr
                          className={`peoplebottom ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div>
                      <hr
                        className={`main-divider ${
                          activeTab === "tab1"
                            ? "eventborder"
                            : activeTab === "tab2"
                            ? "peopleborder"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div>
                    {activeTab === "tab1" && (
                      <div className="conatinerview-scroll">
                        {loadingtext ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">Loading...</span>
                          </div>
                        ) : searchEvents.length === 0 ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">
                              No results found
                            </span>
                          </div>
                        ) : (
                          searchEvents.map((item, index) => (
                            <div
                              className="conatinerview mb-2"
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open("/listing-two/" + item.id)
                              }
                            >
                              <div
                                className="main-section"
                                style={{ height: "80px" }}
                              >
                                <div>
                                  <img
                                    className="eventimg"
                                    src={
                                      item.thumbnail
                                        ? item.thumbnail
                                        : item?.photos[0]
                                        ? item?.photos[0].uploadDocument
                                        : "/static/expertise2.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="innterdata">
                                  <span className="eventname">
                                    {item.title}
                                  </span>
                                  <span className="createdat">
                                    Created by: {item?.createdBy?.name}
                                  </span>
                                  <span className="eventtype">
                                    Event Type:{" "}
                                    {item.listingEventType === "V"
                                      ? "Virtual"
                                      : "Physical"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                    {activeTab === "tab2" && (
                      <div className="conatinerview-scroll">
                        {loadingtext ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">Loading...</span>
                          </div>
                        ) : searchUsers.length === 0 ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">
                              No results found
                            </span>
                          </div>
                        ) : (
                          searchUsers.map((item, index) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open("/our-mates/" + item.id)
                              }
                              className="conatinerview mb-2"
                              key={index}
                            >
                              <div
                                className="main-section-people"
                                style={{ height: "80px" }}
                              >
                                <div>
                                  <img
                                    style={{
                                      borderRadius: "60px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                    className="peopleimg"
                                    src={
                                      item.profilePicture
                                        ? item.profilePicture
                                        : "/static/dummyimg.svg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="innterdata">
                                  <span className="eventnamepeople">
                                    {item.name}
                                  </span>
                                  <span className="createdat">
                                    {item.userType}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="filter-row-container d-none d-md-block">
          <div className="filter-row-wrapper ">
            <div className="filter-first ">
              {desktopInterestList.length > 0 ? (
                <MultipleItems item={desktopInterestList} interests={interests} setInterests={setInterests}/>
              ) : (
                ""
              )}
            </div>
            <div
              className="filter-second"
              style={{
                gap: "0px",
                marginLeft: "1px",
                maxWidth: "181px",
                maxHeight: "48px",
              }}
            >
              <div
                className={`filter-sort-btn ${
                  eventType === "P" ? "orange-color" : "normal-color"
                }`}
                style={{
                  width: "100px",
                  // borderTopRightRadius: '0px',
                  // borderBottomRightRadius: '0px'
                }}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("P");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Physical</p>
                </div>
              </div>
              <div
                style={{
                  width: "100px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                className={`filter-sort-btn  ${
                  eventType === "V"
                    ? "blue-color"
                    : "normal-color normal-color-v"
                }`}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("V");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Virtual</p>
                </div>
              </div>
            </div>
            <div className="filter-second ">
              <div style={{ position: "relative" }}>
                <div
                  className={
                    !isFilterActive ? "filter-sort-btn" : "active-filter-sort"
                  }
                  style={{ marginRight: "6px" }}
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <div className="inner-info">
                    <img src="/static/filter-sort.svg" alt="" />
                    <p>Filter & Sort</p>
                  </div>
                </div>
                {isFilterActive && (
                  <div className="filter-count-wrap">{applyCount}</div>
                )}
              </div>
              <div
                className={showMap ? "show-map-btn" : "show-map-btn-inactive"}
                onClick={() => {
                  setShowMap(!showMap);
                }}
              >
                {showMap ? (
                  <div className="inner-info">
                    <img src="/static/tabler_list.svg" alt="" />
                    <p>Show List</p>
                  </div>
                ) : (
                  <div className="inner-info">
                    <img src="/static/map-icon.svg" alt="" />
                    <p>Show Map</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isMobile && (
          <>
            <div
              className={
                isExpanded
                  ? "heading-and-map-container-res"
                  : "heading-and-map-container"
              }
            >
              {!isExpanded ? (
                <>
                  <div className="heading">Event</div>
                </>
              ) : null}

              <div
                ref={ref}
                className={`res-search-and-sorting-container ${
                  isExpanded ? "expanded" : ""
                }`}
                // onClickOutside={() => setShowDialog(false)}
              >
                <div className="search-input">
                  <img
                    src="static/Search.svg"
                    alt=""
                    style={{ height: "14px", width: "14px" }}
                  />
                  <input
                    style={{ fontSize: "12px" }}
                    placeholder="Search"
                    value={search}
                    onFocus={() => setIsExpanded(true)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        let activeTabVar =
                          activeTab === "tab1" ? "Events" : "Users";
                        navigate(
                          "/search?search=" + search + "&tab=" + activeTabVar
                        );
                      }
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  {search && (
                    <span onClick={handleClearClick} className="clearstyle">
                      X
                    </span>
                  )}
                  {suggstionmodel && (
                    <div className="automodel">
                      {/* Suggestion model */}
                      <div className="Tab-section">
                        <div className="tabcontent">
                          <div>
                            <button
                              className={`eventsstyle ${
                                activeTab === "tab1" ? "active" : ""
                              }`}
                              onClick={() => setActiveTab("tab1")}
                            >
                              Event
                            </button>
                            <hr
                              className={`eventbottom ${
                                activeTab === "tab1" ? "active" : ""
                              }`}
                            />
                          </div>
                          <div>
                            <button
                              className={`Peopleeventstyle ${
                                activeTab === "tab2" ? "active" : ""
                              }`}
                              onClick={() => setActiveTab("tab2")}
                            >
                              People
                            </button>
                            <hr
                              className={`peoplebottom ${
                                activeTab === "tab2" ? "active" : ""
                              }`}
                            />
                          </div>
                        </div>
                        <div>
                          <hr
                            className={`main-divider ${
                              activeTab === "tab1"
                                ? "eventborder"
                                : activeTab === "tab2"
                                ? "peopleborder"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                      {activeTab === "tab1" && (
                        <div className="conatinerview-scroll-res">
                          {loadingtext ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">Loading...</span>
                            </div>
                          ) : searchEvents.length === 0 ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">
                                No results found
                              </span>
                            </div>
                          ) : (
                            searchEvents.map((item, index) => (
                              <div className="">
                                <div
                                  className="conatinerviewres mb-2"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open("/listing-two/" + item.id)
                                  }
                                >
                                  <div
                                    className="main-section"
                                    style={{ height: "80px" }}
                                  >
                                    <div>
                                      <img
                                        className="eventimg-responsive"
                                        src={
                                          item.thumbnail
                                            ? item.thumbnail
                                            : item?.photos[0]
                                            ? item?.photos[0].uploadDocument
                                            : "/static/expertise2.png"
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="innterdata">
                                      <span className="eventnameres">
                                        {item.title}
                                      </span>
                                      <span className="createdat">
                                        Created by: {item?.createdBy?.name}
                                      </span>
                                      <span className="eventtype">
                                        Event Type:{" "}
                                        {item.listingEventType === "V"
                                          ? "Virtual"
                                          : "Physical"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}

                      {activeTab === "tab2" && (
                        <div className="conatinerview-scroll-res">
                          {loadingtext ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">Loading...</span>
                            </div>
                          ) : searchEvents.length === 0 ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">
                                No results found
                              </span>
                            </div>
                          ) : (
                            searchUsers.map((item, index) => (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open("/our-mates/" + item.id)
                                }
                                className="conatinerview mb-3"
                                key={index}
                              >
                                <div
                                  className="main-section mb-2"
                                  style={{ height: "80px" }}
                                >
                                  <div>
                                    <img
                                      style={{
                                        borderRadius: "60px",
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      className="peopleimg"
                                      src={
                                        item.profilePicture
                                          ? item.profilePicture
                                          : "/static/dummyimg.svg"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="innterdata">
                                    <span className="eventname">
                                      {item.name}
                                    </span>
                                    <span className="createdat">
                                      {item.userType}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="res-listing-container ">
              {ResponsiveEventListingType?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setIsLoading(true);
                      setOffset(0);
                      setCurrentPage(0);
                      setListingType(item.type);
                    }}
                    style={{ pointerEvents: isLoading ? "none" : "auto" }}
                    className={`res-selected-type ${
                      listingType === item.type ? "active" : ""
                    }`}
                  >
                    <img
                      src={
                        listingType === item.type
                          ? item.inactiveImg
                          : item.activeImg
                      }
                      alt=""
                    />
                    <p className="mb-0">{item.title}</p>
                  </div>
                );
              })}
            </div>

            {/* <div
              className="filter-sorting-btn"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <img src="static/filter-res.svg" alt="" />
            </div> */}
            <div className="res-filter-list-container">
              {desktopInterestList.map((item) => (
                <div
                  className="filter-item"
                  onClick={() => {
                    let temp_interests = [...interests];
                    if (temp_interests.includes(item.id)) {
                      const index = temp_interests.indexOf(item.id);
                      if (index > -1) {
                        // only splice array when item is found
                        temp_interests.splice(index, 1); // 2nd parameter means remove one item only
                        setInterests([...temp_interests]);
                      }
                    } else {
                      temp_interests.push(item.id);
                      setInterests([...temp_interests]);
                    }
                  }}
                >
                  <img
                    className="filter-item-img"
                    src={item.icon ? item.icon : item.image}
                  />
                  <p
                    className={
                      interests.includes(item.id)
                        ? "active-text"
                        : "filter-text"
                    }
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}

        <div className="filter-row-container-responsive d-block d-sm-none">
          <div className="filter-row-wrapper-responsive pt-3 ">
            <div
              className="filter-second-res"
              style={{
                gap: "0px",
                marginLeft: "1px",
                maxWidth: "181px",
                maxHeight: "48px",
                width: "153px",
              }}
            >
              <div
                className={`filter-sort-btn-res ${
                  eventType === "P" ? "orange-color" : "normal-color"
                }`}
                style={{
                  width: "90px",
                }}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("P");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Physical</p>
                </div>
              </div>
              <div
                style={{
                  width: "90x",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                className={`filter-sort-btn-res  ${
                  eventType === "V"
                    ? "blue-color"
                    : "normal-color normal-color-v-res"
                }`}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("V");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Virtual</p>
                </div>
              </div>
            </div>
            <div className="filter-second-res">
              <div
                className={isFilterActive ? "active-sort-btn" : "sort-btn"}
                style={{ marginRight: "6px", width: "110px " }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <div className="inner-info-res position-relative ">
                  <img
                    src="/static/filter-sort.svg"
                    alt=""
                    style={{ width: "16px", height: "16px" }}
                  />
                  <p style={{ fontSize: "12px" }}>Filter & Sort</p>
                </div>
                {isFilterActive && (
                  <div className="total-filter-conatiner position-absolute">
                    <span className="total-filter-number">{applyCount}</span>
                  </div>
                )}
              </div>
              <div
                style={{ width: "66px" }}
                className={showMap ? "show-map-btn" : "show-map-btn-inactive"}
                onClick={() => {
                  setShowMap(!showMap);
                }}
              >
                {showMap ? (
                  <div className="inner-info">
                    {/* <img src="/static/map_with_grey.svg" alt="" /> */}
                    <img src="/static/map-icon.svg" alt="" />
                  </div>
                ) : (
                  <div className="inner-info">
                    <img src="/static/map-icon.svg" alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="page_loader">
            <div
              className="lds-ring"
              style={{
                margin: "0 auto",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ width: "50px" }}
                  src="/static/spinner.svg"
                  alt="loader"
                />
              </div>

              <div className="text__container">
                <span>Please wait... Loading</span>
              </div>
            </div>
          </div>
        ) : showMap ? (
          <div>
            <EventsMapContainer
              eventMap={eventMap}
              setDistance={setDistance}
              distance={distance}
              // latitude={currentLat}
              // longitude={currentLng}
            />
          </div>
        ) : (
          <>
            <div className="listing-container">
              <div className="listing-inner-wrapper">
                {event.length == 0 ? (
                  <div style={{ height: "400px", width: "100%" }}>
                    <p className="no-content" style={{ margin: "auto" }}>
                      No Events to Display
                    </p>
                  </div>
                ) : (
                  <div className="listings">
                    {event?.map((item: any, index: number) => {
                      return (
                        <div className="mob-diff"  key={index}>
                          <div className="event-link-title h-100">
                            <div className="listing_box">
                              <div className="crousel-container">
                                <div className="span_box">
                                  <span
                                    className="heart"
                                    onClick={() => {
                                      if (!extraLoader) {
                                        setExtraLoader(true);
                                        debouncedToggleFavourite(item);
                                      }
                                    }}
                                  >
                                    {item.isFavourite ? (
                                      <img src="/static/heartred.svg" alt="" />
                                    ) : (
                                      <img
                                        src="/static/card-heart.svg"
                                        alt=""
                                      />
                                    )}
                                  </span>
                                </div>

                                <div className="span_box">
                                  <span
                                    className="share"
                                    onClick={() => {
                                      setDetailsData(item);
                                      setShowShare(true);
                                    }}
                                  >
                                    <img
                                      src="/static/Card-Share-Icon.svg"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="span_box">
                                  {item.listingEventType !== "V" ? (
                                    <>
                                      <img
                                        className="event_type"
                                        src="/static/event-type2.svg"
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span className="event_type">
                                        <img
                                          src="/static/event-type.svg"
                                          alt=""
                                        />
                                      </span>
                                    </>
                                  )}
                                </div>
                                {item.listingEventType !== "V" ? (
                                  <div className="span_box">
                                    {item?.distance ? (
                                      <span className="distance">
                                        {formatNumber(
                                          item.distance?.toFixed(2)
                                        )}{" "}
                                        km away...
                                      </span>
                                    ) : null}
                                  </div>
                                ) : (
                                  ""
                                )}
                              <EventImageCarousel item={item} />   
                                <div className="span_box">
                                  {item?.listingType === LISTING_TYPE.C ? (
                                    <span className="price">Join</span>
                                  ) : item?.price || item?.monthlyCost ? (
                                    <span className="price">
                                      {item?.price == "0" ? (
                                        "Free"
                                      ) : (
                                        <>
                                          {" "}
                                          AUD {item?.price || item?.monthlyCost}
                                        </>
                                      )}
                                    </span>
                                  ) : (
                                    <span className="price">Free</span>
                                  )}
                                </div>
                              </div>

                              <div
                                className="limited-event-info"
                                onClick={(e) => {
                                  e.preventDefault();
                                  scrollToTop();
                                  window.open("/listing-two/" + item.id);
                                }}
                              >
                                <div className="title item">
                                  <a className="event-link-title" href={`#`}>
                                    {item?.title}
                                  </a>
                                </div>
                                <div
                                  className="item"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    minHeight: "16px",
                                  }}
                                >
                                  {item?.address ? (
                                    <>
                                      <img
                                        src="/static/ion_location-outline.svg"
                                        alt=""
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        {
                                          item?.address
                                        }
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {listingType === LISTING_TYPE.C ? null : (
                                  <div
                                    className="item"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <img src="/static/calendar-2.svg" alt="" />
                                    <span style={{ paddingLeft: "4px" }}>
                                      {moment(item?.upcomingEventDate).format(
                                        "DD MMM, YYYY"
                                      ) + " "}
                                    </span>
                                    <img
                                      src="/static/EmptyDot.svg"
                                      style={{ marginBottom: "3px" }}
                                    />
                                    <span style={{ paddingLeft: "4px" }}>
                                      {moment(
                                        item?.upcomingEventStartTime?.substring(
                                          0,
                                          5
                                        ),
                                        ["HH:mm"]
                                      ).format("hh:mmA")}{" "}
                                      -{" "}
                                      {moment(
                                        item?.upcomingEventEndTime?.substring(
                                          0,
                                          5
                                        ),
                                        ["HH:mm"]
                                      ).format("hh:mmA")}
                                    </span>
                                  </div>
                                )}
                              </div>

                              { item?.listingType === LISTING_TYPE.SPECIAL ? (
                                <div
                                  className="name name-special"
                                  style={{
                                    padding: "0px",
                                    marginTop: "14px",
                                  }}
                                >
                                  <img
                                    src={"/static/SpecialNew.svg"}
                                    alt=""
                                    style={{
                                      objectFit: "none",
                                      height: "100%",
                                      width: "60%",
                                      marginRight: "auto",
                                      marginTop: "6px",
                                      paddingTop: "0px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="name"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                      navigate(
                                        `/our-mates/${item?.createdBy?.id}`
                                      );
                                  }}
                                >
                                  <a className="event-link-title">
                                    {["mp4", "mov", "avi"].includes(
                                      item?.createdBy?.profilePicture
                                        ?.split(".")
                                        .splice(-1)[0]
                                        ?.toLowerCase()
                                    ) ? (
                                      <video
                                        disablePictureInPicture
                                        src={item?.createdBy?.profilePicture}
                                        //preload="none"
                                        className="event_img"
                                        autoPlay
                                        //controls
                                        // playsInline
                                        // loop
                                        muted
                                      >
                                        <source
                                          src={item?.createdBy?.profilePicture}
                                        />
                                      </video>
                                    ) : (
                                      <img
                                        style={{ border: "2px solid white" }}
                                        src={
                                          item?.createdBy?.profilePicture ||
                                          "/static/dummyimg.svg"
                                        }
                                        alt=""
                                        loading="lazy"
                                      />
                                    )}
                                  </a>
                                  <span>
                                    {"Event  by "}
                                    {item?.createdBy?.tradingName
                                      ? item?.createdBy?.tradingName
                                      : item?.createdBy?.name}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!showMap && Math.ceil(pageCount / limit) > 1 && (
          <div className="pages-container my-3">
            <div className="pages-inner-container">
              <div className="pagination-box">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      {/* <div className={(Math.ceil(pageCount / limit) === currentPage+1)?"previous-button disabled":"previous-button"}>Next  <img src="/static/u_angle-left-b(1).svg" alt="" /></div> */}

                      <div className="previous-button">
                        {" "}
                        <img src="/static/u_angle-left-b(1).svg" alt="" />{" "}
                        {!isMobile ? "Next " : ""}
                      </div>
                    </>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={!isMobile ? 5 : 2}
                  forcePage={currentPage}
                  pageCount={Math.ceil(pageCount / limit)}
                  previousLabel={
                    <>
                      <div className="previous-button">
                        {!isMobile ? "Previous " : ""}
                        <img src="/static/u_angle-left-b.svg" alt="" />
                      </div>
                    </>
                  }
                  renderOnZeroPageCount={null}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {!showMap && */}
      <div className="create-listing-btn">
        <Button
          btnImg="/static/plusadd.svg"
          btnTxt="Create Listing"
          className="add_listing_btn"
          onClick={() => {
            navigate(ROUTE_CONSTANTS.create_listing);
          }}
        />
      </div>
      {showMap && (
        <div
          className="abs-btn custom_resp_position"
          onClick={() => scrollToBottom()}
        >
          <img
            src="/static/chevron-black-left.svg"
            alt=""
            style={{
              width: "70%",
              height: "70%",
              transform: "rotate(270deg)",
              zIndex: 1500,
            }}
          />
        </div>
      )}
      {/* // } */}
      {detailsData ? (
        <ShareEvents 
          showShare={showShare}
          detailsData={detailsData}
          handleNotShowShare={handleNotShowShare}
          />
      ) : null}
    </>
  );
};

export default Events2;
