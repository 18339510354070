import { Box } from "@mui/material";
import React from "react";

type Props = {
  handleAddTimeSlot: any;
};

const BottomAddSlot = ({ handleAddTimeSlot }: Props) => {
  return (
    <Box>
      <Box className="bottom_slots_container">
        <Box className="add_slot_btn" onClick={handleAddTimeSlot}>
          <img src="/static/arrow/addPrimary.svg" alt="" />
          Add slot
        </Box>
      </Box>
    </Box>
  );
};

export default BottomAddSlot;
