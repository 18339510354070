/* eslint-disable import/no-anonymous-default-export */

import { DIFFICULTY, LISTING_TYPE } from "../types";
import IconBusiness from "../assets/img/signupWelcome/user-bussiness.svg";
import IconIndividual from "../assets/img/signupWelcome/user-individual.svg";
import IconTrainer from "../assets/img/signupWelcome/user-trainer.svg";

export const apiConstants = {
  description: "description",
  email: "email",
  password: "password",
  confirmPassword:"confirmPassword",
  dob: "dob",
  user_type: "userType",
  user_img: "user_img",
  gender: "gender",
  username: "username",
  name: "name",
  bio: "bio",
  profileImage: "profilePicture",
  profileImageExtension: "profilePictureExtension",
  address: "address",
  businessAddress: "businessAddress",
  businessContactEmail: "businessContactEmail",
  abn: "abn",
  businessContactNumber: "businessContactNumber",
  day: "day",
  month: "month",
  year: "year",
  coverPhoto: "coverPhotoVideo",
  coverExtension: "coverExtension",
  interest_search: "",
  isTrainer: "isTrainer",
  business_interest: "business_interest",
  trainer_expertise: "trainer_expertise",
  lat: "lat",
  lng: "lng",
  zip_code: "zip_code",
  state: "state",
  city: "city",
  confirm_password: "confirm_password",
  current_password: "current_password",
  new_password: "new_password",
  link_email: "link_email",
  link_password: "link_password",
  grant_type: "grant_type",
  token: "token",
  code: "code",
  is_ios: "is_ios",
  id_token: "id_token",
  backend: "backend",
  client_id: "client_id",
  client_secret: "client_secret",
  listing_image: "image",
  listing_name: "title",
  media: "media",
  photo: "photo",
  listing_description: "description",
  interest_category: "interest_category",
  listing_location: "location",
  max_participants: "totalParticipants",
  listing_cost: "price",
  schedule_date: "schedule_date",
  difficulty_level: "difficultyLevel",
  is_private: "is_private",
  gender_preference: "gender",
  age_preference: "age_preference",
  specific_gender: "specific_gender",
  interests: "interests",
  min_age: "minAge",
  max_age: "maxAge",
  event_date: "event_date",
  event_custom_date: "event_custom_date",
  event_start_time: "event_start_time",
  event_end_time: "event_end_time",
  event_start_date: "event_start_date",
  event_end_date: "event_end_date",
  repeat_every: "repeat_every",
  is_listing_private: "isListingPrivate",
  recur_frequency: "recur_frequency",
  monthly_frequency: "monthly_frequency",
  postCode: "postCode",
  country: "country",
  image: "image",
  on_the_basis: "on_the_basis",
  isDraft: "isDraft",
  selectedInterest: "selectedInterest",
  is_favourited: "is_favourited",
  existing_or_new: "existingOrNew",
  stripe_account_id: "stripeAccountId",
  listingType: "listingType",
  singleListingType: "singleListingType",
  listingDetails: "listingDetails",
  upload_image: "upload_image",
  is_specific_age: "is_specific_age",
  selected_days: "selected_days",
  custom_dates: "custom_dates",
  event_custom_dates: "event_custom_dates",
  selected_month_dates: "selected_month_dates",
  selected_month_dates_options: "selected_month_dates_options",
  schedule_type: "schedule_type",
  recurring_type: "recurring_type",
  has_schedule_date: "has_schedule_date",
  event_title: "event_title",
  multipleEvents: "multipleEvents",
  isCancellationAllowed: "is_cancellation_allowed",
  payment_mode: "payment_mode",
  cancellation_allowed_till_hours: "cancellation_allowed_till_hours",
  bank_name: "bankName",
  account_number: "accountNumber",
  bsb_number: "brbNumber",
  block_id: "blockId",
  others: "others",
  flagReason: "flag_reason",
  notes: "notes",
  phone_number: "phone_number",
  contact_number: "contact_number",
  message: "message",
  abnDetails: "abnDetails",
  selectedGender: "selectedGender",
  selecteddifficulty: "selecteddifficulty",
  tradingName: "tradingName",
  eventInstruction: "event_instruction",
  selectedInstruction: "selected_instruction",
  listing_event_type: "eventListingType",
  multiple_virtual_links_allowed: "multipleVirtualLinksAllowed",
  virtual_links_single: "virtual_links_single",
  virtual_links_multiple: "virtual_links_multiple",
  virtual_links_package: "virtual_links_package",
  get_connect_profile: "connect/profile/",
  get_connect_suggestions: "connect/users-suggestion/",
  get_flagged_connections: "connect/flagged-users/",
  get_my_favourites: "connect/my-favourites/",
  get_pending_connections: "/connect/pending-connections/",
  get_my_connections: "/connect/my-connections/",
  get_all_users: "auth/search-users-listings/",
  profile_link: "profile_link",
  onBoardingStep:"onboardingStep",
  listing_type: "type",
};

export const meetingLinkRegex = {
  googleMeetRegex: /^https:\/\/meet\.google\.com\/[a-zA-Z0-9-?&=]+$/,
  zoomRegex: /^https:\/\/([a-z0-9]+\.|)zoom\.us\/j\/[a-zA-Z0-9?&=]+$/,
  teamsRegex:
    /^https:\/\/teams\.microsoft\.com\/l\/meetup-join\/[a-zA-Z0-9-?&=]+$/,
};

//Flag User Resons
export const flaggingReasons = [
  { value: "Inappropriate Content", id: "Inappropriate Content" },
  {
    value: "Fake or Misleading Information",
    id: "Fake or Misleading Information",
  },
  { value: "Suspicious Activity", id: "Suspicious Activity" },
  { value: "Harassment or Abuse", id: "Harassment or Abuse" },
  { value: "Impersonation", id: "Impersonation" },
  {
    value: "Violation of Terms of Service",
    id: "Violation of Terms of Service",
  },
  { value: "Spam or Scam", id: "Spam or Scam" },
  {
    value: "Other (Please provide details)",
    id: "Other (Please provide details)",
  },
];

//error messages
export const errorMessage = {
  fieldRequired: (text: string) => `Please enter ${text}.`,
  selectFieldRequired: (text: string) => `Please select ${text}.`,
  phone_validation: "Phone number is not valid",
  email_validation: "Email is not valid.",
  empty_login_pin: "Please enter login pin.",
  server_error: "Server Error. Please try after some time.",
};

export const userTypeOptions = [
  {
    label: "Guest",
    msg: "Find fitness partners and events near you.",
    value: "Guest",
    img: "/static/emoji-laugh.svg",
  },
  {
    label: "Member",
    msg: "Suitable for personal trainers, medical professionals, gyms, businesses and other persons.",
    value: "Member",
    img: "/static/dumbbell.svg",
  },
];

export const recurFrequency = ["Day", "Week", "Month"];

export const genderOption = [
  {
    id: "m",
    label: "Male",
    value: "M",
    img: "/static/male.svg",
  },
  {
    id: "f",
    label: "Female",
    value: "F",
    img: "/static/female.svg",
  },
  {
    id: "nb",
    label: "Prefer not to say",
    value: "N",
    img: "/static/nonbinary.svg",
  },
];
export const businessInterest = [
  {
    img: "/static/yoga.png",
    value: "Dance",
  },
  {
    img: "/static/yoga.png",
    value: "Trip",
  },
  {
    img: "/static/yoga.png",
    value: "Yoga",
  },
  {
    img: "/static/yoga.png",
    value: "Aerobic",
  },

  {
    img: "/static/yoga.png",
    value: "Trip1",
  },
  {
    img: "/static/yoga.png",
    value: "Yoga1",
  },
  {
    img: "/static/yoga.png",
    value: "Aerobic1",
  },

  {
    img: "/static/yoga.png",
    value: "Trip2",
  },
  {
    img: "/static/yoga.png",
    value: "Yoga2",
  },
  {
    img: "/static/yoga.png",
    value: "Aerobic2",
  },
];
export const trainerExpertise = [
  { img: "/static/expertise1.png", value: "Dance" },
  { img: "/static/expertise2.png", value: "Yoga" },
  { img: "/static/expertise1.png", value: "Yoga2" },
  { img: "/static/expertise2.png", value: "Dance2" },
];
export const ListingTypesForGuest = [
  {
    icon: "/static/tag.svg",
    title: "Create a single/ multiple sessions",
    desc: "Create your listing name, location & payment price",
    type: LISTING_TYPE.S,
  },
  {
    icon: "/static/box.svg",
    title: "Create a Package",
    desc: "Sell multiple classes as a single,convenient listing.",
    type: LISTING_TYPE.M,
  },
];
export const ListingTypesForMember = [
  {
    icon: "/static/tag.svg",
    title: "Create a single/ multiple sessions",
    desc: "Create your listing name, location & payment price.",
    type: LISTING_TYPE.S,
  },
  {
    icon: "/static/box.svg",
    title: "Create a Package",
    desc: "Sell multiple classes as a single,convenient listing.",
    type: LISTING_TYPE.M,
  },
  {
    icon: "/static/people-team-toolbox.svg",
    title: "Create a team",
    desc: "Connect with like-minded individuals.",
    type: LISTING_TYPE.C,
  },
];
export const filterOption = [
  { label: "Most popular", value: "popularity" },
  { label: "Recently added", value: "recently_added" },
  { label: "Sort A to Z", value: "title" },
  { label: "Sort Z to A", value: "-title" },
];
export const filterOptionEvents = [
  { label: "Closest to me", value: "closest" },
  { label: "Most popular", value: "popular" },
  { label: "Recommended", value: "recommended" },
];

export const difficultyLevelPreference = [
  {
    id: DIFFICULTY.B,
    value: "Beginner",
    img: "/static/ribbon-gray.svg",
    activeImg: "/static/Ribbon-orange.svg",
  },
  {
    id: DIFFICULTY.I,
    value: "Intermediate",
    img: "/static/ribbon-star2.svg",
    activeImg: "/static/ribbon-star-orange.svg",
  },
  {
    id: DIFFICULTY.A,
    value: "Advanced",
    img: "/static/trophy_grey.svg",
    activeImg: "/static/trophy-orange.svg",
  },
];

export const WeekDays = [
  {
    label: "Sun",
    value: "Sunday",
  },
  {
    label: "Mon",
    value: "Monday",
  },
  {
    label: "Tue",
    value: "Tuesday",
  },
  {
    label: "Wed",
    value: "Wednesday",
  },
  {
    label: "Thu",
    value: "Thursday",
  },
  {
    label: "Fri",
    value: "Friday",
  },
  {
    label: "Sat",
    value: "Saturday",
  },
];
export const timeout = 200;
export const toastTimeout = 3000;
export const IMG_BASE_URL = process.env.REACT_APP_IMG_URL;
export const googleKey = "AIzaSyBfndC_Qxf9tApzas17Jrmpttahz1fMdFY";
export const checkPasswordRegex = /^(?=.*[A-Z]).+$/;
export const numericRegex = /^\s*[1-9](\s*\d){10}\s*$/;
export const EventListingType = [
  {
    type: LISTING_TYPE.S,
    inactiveImg: "/static/tag.svg",
    activeImg: "/static/taginactive.svg",
    title: "Book Sessions",
    title_draft: "Sessions",
  },
  {
    type: LISTING_TYPE.M,
    inactiveImg: "/static/box.svg",
    activeImg: "/static/boxinactive.svg",
    title: "Book a Package",
    title_draft: "Package",
  },
  {
    type: LISTING_TYPE.C,
    inactiveImg: "/static/people-team-toolbox.svg",
    activeImg: "/static/peopleinactive.svg",
    title: "Join a Team",
    title_draft: "Team",
  },
];

export const EventListingTypeGuest = [
  {
    type: LISTING_TYPE.S,
    inactiveImg: "/static/tag.svg",
    activeImg: "/static/taginactive.svg",
    title: "Book Sessions",
    title_draft: "Sessions",
  },
  {
    type: LISTING_TYPE.M,
    inactiveImg: "/static/box.svg",
    activeImg: "/static/boxinactive.svg",
    title: "Book a Package",
    title_draft: "Package",
  },
];

export const CancelReason = [
  "Sudden illness or injury",
  "Unforeseen scheduling conflict",
  "Personal emergency",
  "Travel-related issues",
  "Change in work commitments",
  "Family obligations or responsibilities",
];

export const FlagListingReason = [
  "Misleading Information",
  "Inappropriate Content",
  "Spam or Scam",
  "Irrelevant Listing",
  "Duplicate Listing",
  "Offensive Language or Behavior",
  "Copyright Infringement",
];

export const EventListingChipType = [
  {
    type: "closest",
    inactiveImg: "/static/gps.svg",
    activeImg: "/static/gpsInactive.svg",
    title: "Nearby",
    label: "Closest to me",
    value: "closest",
  },
  {
    type: "recommended",
    inactiveImg: "/static/FireMinimalisticIactive.svg",
    activeImg: "/static/FireMinimalistic.svg",
    title: "What’s trending",
    label: "Recommended",
    value: "recommended",
  },
  {
    type: "popular",
    inactiveImg: "/static/likeInactive.svg",
    activeImg: "/static/like.svg",
    title: "Most popular",
    label: "Most popular",
    value: "popular",
  },
  {
    type: "free",
    inactiveImg: "/static/peopleInactives.svg",
    activeImg: "/static/people.svg",
    title: "Social",
    label: "Social",
    value: "free",
  },
];

export enum Room_type {
  G = "G",
  D = "D",
  L = "L",
}

export const difficultyLevelPreferenceNew = [
  {
    value: "B",
    title: "Beginner",
    img: "/static/ribbon-gray.svg",
    activeImg: "/static/Ribbon-orange.svg",
  },
  {
    value: "I",
    title: "Intermediate",
    img: "/static/ribbon-star2.svg",
    activeImg: "/static/ribbon-star-orange.svg",
  },
  {
    value: "A",
    title: "Advanced",
    img: "/static/trophy_grey.svg",
    activeImg: "/static/trophy-orange.svg",
  },
];
export const ResponsiveEventListingType = [
  {
    type: LISTING_TYPE.S,
    inactiveImg: "/static/tag.svg",
    activeImg: "/static/taginactive.svg",
    title: "Sessions",
  },
  {
    type: LISTING_TYPE.M,
    inactiveImg: "/static/box.svg",
    activeImg: "/static/boxinactive.svg",
    title: "Package",
  },
  {
    type: LISTING_TYPE.C,
    inactiveImg: "/static/people-team-toolbox.svg",
    activeImg: "/static/peopleinactive.svg",
    title: "Team",
  },
];

export const TEAM_REQUEST_TYPE_PAYLOAD = {
  TOTAL: "R",
  ACCEPTED: "A",
  REJECTED: "D",
};

export const FILTER_DEFAULT_VALUES = {
  address: "",
  chipType: "closest",
  endDate: "",
  eventType: "P",
  gender: "",
  maxAge: "",
  maxPrice: "",
  minAge: "",
  minPrice: "",
  startDate:"",
  interests: [],
}

export const GENDER_TYPE = {
  MALE:"M",
  FEMALE:"F",
  PREFER_NOT_TO_SAY:"P"
}

export const FILTER_PRICE_RANGE = {
  MIN: 0,
  MAX: 500
}

export const SORT_FIELD_DATA = [
  {label: "Radius Nearest" , value:"closest" , defaultChecked: true, sortBy:"closest"},
  {label: "Price: Low to high" , value:"physical" , defaultChecked: false, sortBy:"price_low"},
  {label: "Price: High to Low" , value:"Both" , defaultChecked: false, sortBy:"price_high"},
  {label: "Most Popular" , value:"popular" , defaultChecked: false, sortBy:"popular"},
  {label: "Earliest" , value:"Both" , defaultChecked: false, sortBy:"earliest"},
]
export const APP_ENV = {
  PRODUCTION: "production",
  STAGING:"staging",
  DEVELOPMENT:"dev",
}

export const memberTypeData = [
  {
    label: "Business",
    img: IconBusiness,
    alt: "business",
    isSelected: false,
  },
  {
    label: "Individual",
    img: IconIndividual,
    alt: "individual",
    isSelected: false,
  },
  {
    label: "Trainer",
    img: IconTrainer,
    alt: "trainer",
    isSelected: false,
  },
];

export const EVENT_COST = { MAX:500, MIN:0 }
