/* eslint-disable react-hooks/exhaustive-deps */
import { useDebugValue, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LISTING_TYPE, USER_TYPE } from "../../../types";
import {
  getEventsList,
  getItemFromStore,
  showToast,
  getEventsListMap,
  getSearchList,
  scrollToTop,
  formatNumber,
} from "../../../utils";
import {FILTER_DEFAULT_VALUES } from "../../../utils/Constants";
import ReactPaginate from "react-paginate";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Button from "../../../global/Button";
import GlobalHeader2 from "../GlobalHeader2";
import GlobalFooter from "../GlobalFooter";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import FilterPopup from "./FilterPopup";
import EventsMapContainer from "./EventsMapContainer";
import moment from "moment";
import img from "../../../assets/img/fitness.png";
import endpoints from "../../../utils/endpoints";
import {
  makeDeleteCall,
  makePostCall,
} from "../../../utils/Requests";
import dayjs from "dayjs";
import EventImageCarousel from "./EventImageCarousel";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";

const EventsSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef<HTMLDivElement>(null);

  const [event, setEvent] = useState<any>([]);
  const [eventMap, setEventMap] = useState<any>([]);
  const [loadingtext, setLoadingtext] = useState(false); // Add loading state

  const [nextUrl, setNextUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [currentLat, setCurrentLat] = useState<any>(null);
  const [currentLng, setCurrentLng] = useState<any>(null);
  const [distance, setDistance] = useState<any>(5000);
  const [isLocationAccessible, setIslocationAccessible] = useState<any>(null);
  const [showMap, setShowMap] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [limit, setLimit] = useState(16);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [desktopInterestList, setDesktopInterestList] = useState([]);
  const [interests, setInterests] = useState([]);
  const [search, setSearch] = useState("");
  const searchData = useDebugValue(search);
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [applyCount, setApplyCount] = useState(0);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [eventType, setEventType] = useState("");
  const [activeTab, setActiveTab] = useState("tab1");
  const [searchEvents, setSearchEvents] = useState([]);
  const [firstTimer, setFirstTimer] = useState(0);

  const [searchUsers, setSearchUsers] = useState([]);
  const [listingType, setListingType] = useState("S,SPECIAL,P,T");
  const [counter, setCounter] = useState(0);

  const [suggstionmodel, setsuggstionmodel] = useState(false);
  const { isMobile } = useCheckDeviceScreen();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [eventFilterParams, setEventFilterParams] = useState(FILTER_DEFAULT_VALUES);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchValue(search);
    }, 1500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [search, 1500]);

  useEffect(() => {
    setInterests([]);
    setLimit(16);
    setOffset(0);
    setDebouncedSearchValue("");
    setEventType("");
    setListingType("S,SPECIAL,P,T");
    setShowMap(false);
  }, [location]);

  useEffect(() => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLat(position.coords.latitude);
          setCurrentLng(position.coords.longitude);
          setIslocationAccessible(true);
        },
        (err) => {
          setIslocationAccessible(false);
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      setIslocationAccessible(false);
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const {startDate , endDate , minPrice, maxPrice, minAge, maxAge, gender, chipType } = eventFilterParams;
    let params = {
      listing_type: listingType,
      sort: chipType,
      distance_filter: false,
      limit: limit,
      offset: offset,
      search_title: debouncedSearchValue,
      event_type: eventType,
      ...(minPrice !== "" && { min_price: minPrice }),
      ...(maxPrice && { max_price: maxPrice }),
      ...(gender && { gender }),
      // commenting below line and keeping above as of now because condition check cause server error in api when search_title is not in payload
      // ...(debouncedSearchValue && { search_title: debouncedSearchValue }),
      ...(interests.length > 0 && { interests: interests.toString() })
    };
    if (
      listingType !== LISTING_TYPE.C &&
      currentLng &&
      currentLat &&
      chipType === "closest"
    ) {
      params["dist"] = distance;
      params["point"] = `${currentLng},${currentLat}`;
    }

    if (long && lat) {
      params["dist"] = distance;
      params["point"] = `${long},${lat}`;
    }
    if (minAge) {
      params["age"] = minAge + "," + maxAge;
    }
    if (startDate && endDate) {
      params["dates"] =
        dayjs(startDate).format("YYYY-MM-DD") +
        "," +
        dayjs(endDate).format("YYYY-MM-DD");
      //dates: moment(startDate).format("YYYY-MM-DD") + "," + moment(endDate).format("YYYY-MM-DD"),
    }
    if (debouncedSearchValue || firstTimer > 0) {
      if (showMap) {
        getEventsListMap(
          params,
          setEventMap,
          setNextUrl,
          setIsLoading,
          setPageCount
        );
      } else {
        if (isLocationAccessible === true && currentLng && currentLat) {
          getEventsList(
            params,
            setEvent,
            setNextUrl,
            setIsLoading,
            setPageCount
          );
        } else if (isLocationAccessible === false) {
          getEventsList(
            params,
            setEvent,
            setNextUrl,
            setIsLoading,
            setPageCount
          );
        }
        setFirstTimer(1);
      } 
      // commenting below code as its causing multiple times api invok and also seems not usefull in current implentation;
      // } 
      // else {
      //   getEventsList(
      //     params,
      //     setEvent,
      //     setNextUrl,
      //     setIsLoading,
      //     setPageCount
      //   );
    } 
  }, [
    eventFilterParams?.chipType,
    distance,
    currentLat,
    currentLng,
    isLocationAccessible,
    listingType,
    offset,
    interests,
    debouncedSearchValue,
    applyCount,
    showMap,
  ]);

  useEffect(() => {
    if (!debouncedSearchValue) {
      setsuggstionmodel(false);
    } else {
      setIsLoading(true);
      setLoadingtext(true);
      getSearchList(
        {
          search: debouncedSearchValue,
          tab: activeTab === "tab1" ? "Events" : "Users",
        },
        (events) => {
          setSearchEvents(events);
          setLoadingtext(false);
        },
        (users) => {
          setSearchUsers(users);
          setLoadingtext(false);
        }
      );
      // setLoadingtext(false);
      setIsLoading(false);
    }

    if (!debouncedSearchValue && activeTab === "tab2") {
      setIsLoading(true);
      setLoadingtext(true);
      getSearchList(
        {
          search: debouncedSearchValue,
          tab: "Users",
        },
        (events) => {
          setSearchEvents(events);
          setLoadingtext(false);
        },
        (users) => {
          setIsLoading(false);
          setSearchUsers(users);
          setLoadingtext(false);
        }
      );
    }
  }, [debouncedSearchValue, activeTab, counter]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const search_string = queryParams.get("search");
    const tab = queryParams.get("tab");
   
    if (search_string && tab) {
      let newActiveTab = tab === "Events" ? "tab1" : "tab2";
      setSearch(search_string);
      setActiveTab(newActiveTab);
    }
  }, []);

  const handleClearClick = (e: any) => {
    setsuggstionmodel(false);
    setSearch("");
    setSearchEvents([]);
    setActiveTab("tab1");
  };

  const handleClearClickSmall = (e: any) => {
    setsuggstionmodel(false);
  };

  const handlePageClick = (event) => {
    scrollToTop();
    let page = event.selected + 1;
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
  };

  const removeActivity = (id: string, markBy: string, url: string) => {
    let apiPayload = {
      user: id,
      markedBy: markBy,
    };
    makeDeleteCall({ url, apiPayload, content_type: "multipart/form-data" })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          let temp_counter = counter;
          setCounter(++temp_counter);
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const AddActivity = (id: string, markBy: string, url: string) => {
    let apiPayload = {
      user: id,
      markedBy: markBy,
    };
    makePostCall({ url, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          let temp_counter = counter;
          setCounter(++temp_counter);
          // if (url === endpoints.favourite) {
          // }
          // if (url === endpoints.flag) {
          // }
        }
      })
      .catch((err) => showToast(err, "error"));
  };

  const connectUser = (connectid) => {
    makePostCall({
      url: `auth/${connectid}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          let temp_counter = counter;
          setCounter(++temp_counter);
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  
  return (
    <>
      <GlobalHeader2 />
      <div className="events-page">
        <div className="selected-listing-type-container">
          <div
            className={
              suggstionmodel === true
                ? "selected-listing-type-wrapper flex-mobile-set-search-full"
                : "selected-listing-type-wrapper flex-mobile-set-search"
            }
          >
            <div
              ref={searchRef}
              className="listing-type-right"
              style={{ width: "100%", marginRight: "20px" }}
            >
              <img src="static/Search.svg" alt="" />
              <input
                placeholder="Search for any Event or Individual"
                style={{ width: "100%" }}
                value={search}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    let activeTabVar =
                      activeTab === "tab1" ? "Events" : "Users";
                    setsuggstionmodel(false);
                    navigate(
                      "/search?search=" + search + "&tab=" + activeTabVar
                    );
                  }
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (e.target.value) {
                    setsuggstionmodel(true);
                  } else {
                    setsuggstionmodel(false);
                  }
                }}
              />
              {search && (
                <span onClick={handleClearClick} className="clearstyle">
                  Clear
                </span>
              )}

            </div>

            {activeTab === "tab1" ? (
              <div
                className="filter-row-container d-none d-md-block"
                style={{ padding: "0px" }}
              >
                <div className="filter-row-wrapper ">
                  <div className="filter-second ">
                    <div
                      className="filter-sort-btn"
                      onClick={() => {
                        setShowFilter(!showFilter);
                      }}
                    >
                      <div className="inner-info">
                        <img src="/static/filter-sort.svg" alt="" />
                        <p>Filter & Sort</p>
                      </div>
                    </div>
                    <div
                      className={
                        showMap ? "show-map-btn" : "show-map-btn-inactive"
                      }
                      onClick={() => {
                        setShowMap(!showMap);
                      }}
                    >
                      {showMap ? (
                        <div className="inner-info">
                          <img src="/static/tabler_list.svg" alt="" />
                          <p>Show List</p>
                        </div>
                      ) : (
                        <div className="inner-info">
                          <img src="/static/map-icon.svg" alt="" />
                          <p>Show Map</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {isMobile && !suggstionmodel && (
            <div className="selected-listing-type-wrapper flex-mobile-set-search-2">
              {activeTab === "tab1" ? (
                <div
                  className="filter-row-container"
                  style={{ padding: "0px" }}
                >
                  <div className="filter-row-wrapper ">
                    <div className="filter-second ">
                      <div
                        className="filter-sort-btn"
                        onClick={() => {
                          setShowFilter(!showFilter);
                        }}
                      >
                        <div className="inner-info">
                          <img src="/static/filter-sort.svg" alt="" />
                          {/* <p>Filter & Sort</p> */}
                        </div>
                      </div>
                      {showMap ? (
                        <div
                          onClick={() => {
                            setShowMap(!showMap);
                          }}
                        >
                          <img
                            src="/static/tabler_list.svg"
                            alt=""
                            style={{ width: "44px", height: "44px" }}
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setShowMap(!showMap);
                          }}
                        >
                          <img
                            src="/static/map_with_grey.svg"
                            alt=""
                            style={{ width: "44px", height: "44px" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="selected-listing-type-container"></div>

        {isMobile && false && (
          <>
            <div className={"heading-and-map-container"}>
              <div className="heading">Events</div>
              <div className="listing-map-container">
                {showMap ? (
                  <div
                    onClick={() => {
                      setShowMap(!showMap);
                    }}
                  >
                    <img src="/static/tabler_list.svg" alt="" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setShowMap(!showMap);
                    }}
                  >
                    <img src="/static/map_with_grey.svg" alt="" />
                  </div>
                )}
              </div>
            </div>

            <div className="res-search-and-sorting-container">
              <div className="search-input">
                <img src="static/Search.svg" alt="" />
                <input
                  placeholder="Search for any Event or Individual"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      let activeTabVar =
                        activeTab === "tab1" ? "Events" : "Users";
                      setsuggstionmodel(false);
                      navigate(
                        "/search?search=" + search + "&tab=" + activeTabVar
                      );
                    }
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value) {
                      setsuggstionmodel(true);
                    } else {
                      setsuggstionmodel(false);
                    }
                  }}
                />
                {search && (
                  <span onClick={handleClearClick} className="clearstyle">
                    X
                  </span>
                )}
                {suggstionmodel && (
                  <div className="secondautomodel">
                    {/* Suggestion model */}
                    <div className="Tab-section">
                      <div className="tabcontent">
                        <div>
                          <button
                            className={`eventsstyle ${
                              activeTab === "tab1" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("tab1")}
                          >
                            Event
                          </button>
                          <hr
                            className={`eventbottom ${
                              activeTab === "tab1" ? "active" : ""
                            }`}
                          />
                        </div>
                        <div>
                          <button
                            className={`Peopleeventstyle ${
                              activeTab === "tab2" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("tab2")}
                          >
                            People
                          </button>
                          <hr
                            className={`peoplebottom ${
                              activeTab === "tab2" ? "active" : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div>
                        <hr
                          className={`main-divider ${
                            activeTab === "tab1"
                              ? "eventborder"
                              : activeTab === "tab2"
                              ? "peopleborder"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    {activeTab === "tab1" && (
                      <div className="conatinerview-scroll-res">
                        {loadingtext ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">Loading...</span>
                          </div>
                        ) : searchEvents.length === 0 ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">
                              No results found
                            </span>
                          </div>
                        ) : (
                          searchEvents.map((item, index) => (
                            <div className="">
                              <div
                                className="conatinerviewres mb-2"
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open("/listing-two/" + item.id)
                                }
                              >
                                <div
                                  className="main-section"
                                  style={{ height: "80px" }}
                                >
                                  <div>
                                    <img
                                      className="eventimg-responsive"
                                      src={
                                        item.thumbnail
                                          ? item.thumbnail
                                          : item?.photos[0]
                                          ? item?.photos[0].uploadDocument
                                          : "/static/expertise2.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="innterdata">
                                    <span className="eventnameres">
                                      {item.title}
                                    </span>
                                    <span className="createdat">
                                      Created by: {item?.createdBy?.name}
                                    </span>
                                    <span className="eventtype">
                                      Event Type:{" "}
                                      {item.listingEventType === "V"
                                        ? "Virtual"
                                        : "Physical"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                    {activeTab === "tab2" && (
                      <div className="conatinerview-scroll-res">
                        {loadingtext ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">Loading...</span>
                          </div>
                        ) : searchEvents.length === 0 ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">
                              No results found
                            </span>
                          </div>
                        ) : (
                          searchUsers.map((item, index) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open("/our-mates/" + item.id)
                              }
                              className="conatinerview mb-3"
                              key={index}
                            >
                              <div
                                className="main-section mb-2"
                                style={{ height: "80px" }}
                              >
                                <div>
                                  <img
                                    style={{
                                      borderRadius: "60px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                    className="peopleimg"
                                    src={
                                      item.profilePicture
                                        ? item.profilePicture
                                        : "/static/dummyimg.svg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="innterdata">
                                  <span className="eventname">{item.name}</span>
                                  <span className="createdat">
                                    {item.userType}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              className="filter-sorting-btn"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <img src="static/filter-res.svg" alt="" />
            </div>
            <div className="res-filter-list-container">
              {desktopInterestList.map((item) => (
                <div
                  className="filter-item"
                  onClick={() => {
                    let temp_interests = [...interests];
                    if (temp_interests.includes(item.id)) {
                      const index = temp_interests.indexOf(item.id);
                      if (index > -1) {
                        // only splice array when item is found
                        temp_interests.splice(index, 1); // 2nd parameter means remove one item only
                        setInterests([...temp_interests]);
                      }
                    } else {
                      temp_interests.push(item.id);
                      setInterests([...temp_interests]);
                    }
                  }}
                >
                  <img
                    src={item.icon ? item.icon : item.image}
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p
                    className={interests.includes(item.id) ? "active-text" : ""}
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}

        {isLoading ? (
          <>
            <div className="listing-container">
              <div className="listing-inner-wrapper">
                <div className="Tab-section Tab-Section-Custom-CSS">
                  <div className="tabcontent">
                    <div
                      style={{ flexBasis: 0, flexGrow: 1, textAlign: "center" }}
                    >
                      <button
                        className={`eventsstyle ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab1")}
                      >
                        Event
                      </button>
                      <hr
                        className={`eventbottom ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                      />
                    </div>
                    <div
                      style={{ flexBasis: 0, flexGrow: 1, textAlign: "center" }}
                    >
                      <button
                        className={`Peopleeventstyle ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab2")}
                      >
                        People
                      </button>
                      <hr
                        className={`peoplebottom ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div>
                    <hr
                      className={`main-divider ${
                        activeTab === "tab1"
                          ? "eventborder"
                          : activeTab === "tab2"
                          ? "peopleborder"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="page_loader">
              <div
                className="lds-ring"
                style={{
                  margin: "0 auto",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ width: "50px" }}
                    src="/static/spinner.svg"
                    alt="loader"
                  />
                </div>

                <div className="text__container">
                  <span>Please wait... Loading</span>
                </div>
              </div>
            </div>
          </>
        ) : showMap ? (
          <div>
            <EventsMapContainer
              eventMap={eventMap}
              setDistance={setDistance}
              distance={distance}
            />
          </div>
        ) : (
          <>
            <div className="listing-container">
              <div className="listing-inner-wrapper">
                <div className="Tab-section Tab-Section-Custom-CSS">
                  <div className="tabcontent">
                    <div
                      style={{ flexBasis: 0, flexGrow: 1, textAlign: "center" }}
                    >
                      <button
                        className={`eventsstyle ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab1")}
                      >
                        Event
                      </button>
                      <hr
                        className={`eventbottom ${
                          activeTab === "tab1" ? "active" : ""
                        }`}
                      />
                    </div>
                    <div
                      style={{ flexBasis: 0, flexGrow: 1, textAlign: "center" }}
                    >
                      <button
                        className={`Peopleeventstyle ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("tab2")}
                      >
                        People
                      </button>
                      <hr
                        className={`peoplebottom ${
                          activeTab === "tab2" ? "active" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div>
                    <hr
                      className={`main-divider ${
                        activeTab === "tab1"
                          ? "eventborder"
                          : activeTab === "tab2"
                          ? "peopleborder"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                {activeTab === "tab1" ? (
                  event.length == 0 ? (
                    <div style={{ height: "400px", width: "100%" }}>
                      <p className="no-content" style={{ margin: "auto" }}>
                        No Events to Display
                      </p>
                    </div>
                  ) : (
                    <div className="listings">
                      {event?.map((item: any, index: number) => {
                        return (
                          <div key={index}>
                            <div className="event-link-title">
                              <div className="listing_box">
                                <div className="crousel-container">
                                  <div className="span_box">
                                    {item.listingEventType !== "V" ? (
                                      <>
                                        <img
                                          className="event_type"
                                          src="/static/event-type2.svg"
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <span className="event_type">
                                          <img
                                            src="/static/event-type.svg"
                                            alt=""
                                          />
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  <div className="span_box">
                                    <span
                                      className="heart"
                                      // onClick={() => {
                                      //   const isAuthenticated = getItemFromStore(
                                      //     "access_token"
                                      //   )
                                      //     ? true
                                      //     : false;
                                      //   if (isAuthenticated) {
                                      //     if (item.isFavourite) {
                                      //       handleDislike(item.id);
                                      //     } else {
                                      //       handleLike(item.id);
                                      //     }
                                      //   } else {
                                      //     navigate(`/login`);
                                      //   }
                                      // }}
                                    >
                                      {item.isFavourite ? (
                                        <img
                                          src="/static/heartred.svg"
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src="/static/card-heart.svg"
                                          alt=""
                                        />
                                      )}
                                    </span>
                                  </div>

                                  <div className="span_box">
                                    <span
                                      className="share"
                                      // onClick={() => {
                                      //   setDetailsData(item);
                                      //   setShowShare(true);
                                      // }}
                                    >
                                      <img
                                        src="/static/Card-Share-Icon.svg"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  {item.listingEventType !== "V" ? (
                                    <div className="span_box">
                                      {item?.distance ? (
                                        <span className="distance">
                                          {formatNumber(
                                            item.distance?.toFixed(2)
                                          )}{" "}
                                          km away...
                                        </span>
                                      ) : null}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <EventImageCarousel item={item} />

                                  <div className="span_box">
                                    {item?.listingType === LISTING_TYPE.C ? (
                                      <span className="price">Join</span>
                                    ) : item?.price || item?.monthlyCost ? (
                                      <span className="price">
                                        {Number(item?.price) > 1 ? (
                                          "Free"
                                        ) : (
                                          <>
                                            {" "}
                                            AUD{" "}
                                            {item?.price || item?.monthlyCost}
                                          </>
                                        )}
                                      </span>
                                    ) : (
                                      <span className="price">Free</span>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="limited-event-info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    scrollToTop();
                                    window.open(`/listing-two/${item.id}`);
                                  }}
                                >
                                  <div className="title item">
                                    {/* <Link
                                      className="event-link-title"
                                      to={`listing-two/${item.id}`}
                                    > */}
                                    {item?.title}
                                    {/* </Link> */}
                                  </div>
                                  <div
                                    className="item"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <img
                                      src="/static/ion_location-outline.svg"
                                      alt=""
                                    />
                                    {item?.address ? item?.address : ""}
                                  </div>
                                  {item?.listingType ===
                                  LISTING_TYPE.C ? null : (
                                    <div
                                      className="item"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <img
                                        src="/static/calendar-2.svg"
                                        alt=""
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        {moment(item?.upcomingEventDate).format(
                                          "DD MMM, YYYY"
                                        )}
                                        <img
                                          src="/static/EmptyDot.svg"
                                          style={{
                                            marginBottom: "3px",
                                            marginLeft: "4px",
                                          }}
                                          alt=""
                                        />
                                        <span style={{ paddingLeft: "4px" }}>
                                          {moment(
                                            item?.upcomingEventStartTime?.substring(
                                              0,
                                              5
                                            ),
                                            ["HH:mm"]
                                          ).format("hh:mmA")}{" "}
                                          -{" "}
                                          {moment(
                                            item?.upcomingEventEndTime?.substring(
                                              0,
                                              5
                                            ),
                                            ["HH:mm"]
                                          ).format("hh:mmA")}
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {item?.listingType === LISTING_TYPE.SPECIAL ? (
                                  <div
                                    className="name name-special"
                                    style={{
                                      padding: "0px",
                                      // borderTopLeftRadius: "0px",
                                      // borderTopRightRadius: "0px",
                                      marginTop: "14px",
                                    }}
                                  >
                                    <img
                                      src={img}
                                      alt=""
                                      style={{
                                        height: "100%",
                                        width: "50%",
                                        marginRight: "auto",
                                        paddingTop: "2px",
                                      }}
                                    />
                                    {/* <span>Fitness Mates</span> */}
                                  </div>
                                ) : (
                                  <div
                                    className="name"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        navigate(
                                          `/our-mates/${item?.createdBy?.id}`
                                        );
                                    }}
                                  >
                                    <Link
                                      className="event-link-title"
                                      to={`listing-two/${item.id}`}
                                    >
                                      {["mp4", "mov", "avi"].includes(
                                        item?.createdBy?.profilePicture
                                          ?.split(".")
                                          .splice(-1)[0]
                                          ?.toLowerCase()
                                      ) ? (
                                        <video
                                          src={item?.createdBy?.profilePicture}
                                          //preload="none"
                                          className="event_img"
                                          autoPlay
                                          //controls
                                          // playsInline
                                          // loop
                                          muted
                                        >
                                          <source
                                            src={
                                              item?.createdBy?.profilePicture
                                            }
                                          />
                                        </video>
                                      ) : (
                                        <img
                                          src={
                                            item?.createdBy?.profilePicture ||
                                            "/static/dummyimg.svg"
                                          }
                                          alt=""
                                          loading="lazy"
                                        />
                                      )}
                                    </Link>
                                    <span>
                                      {" "}
                                      {item?.createdBy?.tradingName
                                        ? item?.createdBy?.tradingName
                                        : item?.createdBy?.name}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                ) : searchUsers.length == 0 ? (
                  <div style={{ height: "400px", width: "100%" }}>
                    {isLoading ? (
                      <p className="no-content" style={{ margin: "auto" }}>
                        Fetching Users..
                      </p>
                    ) : (
                      <p className="no-content" style={{ margin: "auto" }}>
                        No Users to display
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="listings">
                    {searchUsers.map((item, index) => (
                      <div className={`connect-card col-md-3`}>
                        <div
                          className="card-content-wrap"
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                        >
                          <div
                            className="background-image backk"
                            style={{
                              backgroundImage: item?.thumbnail
                                ? `url(${item.thumbnail})`
                                : item?.profilePicture
                                ? `url(${item?.profilePicture})`
                                : "rgb(68, 78, 78)",
                              backgroundColor: "rgb(68, 78, 78)",
                            }}
                          >
                            {["mp4", "mov", "avi"].includes(
                              item?.coverPhotoVideo
                                ?.split(".")
                                .splice(-1)[0]
                                .toLowerCase()
                            ) ? (
                              <video
                                src={item?.coverPhotoVideo}
                                autoPlay
                                playsInline
                                loop
                                muted
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  verticalAlign: "middle",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <source src={item?.coverPhotoVideo} />
                              </video>
                            ) : null}
                          </div>

                          <div className="content" style={{ width: "100%" }}>
                            {["mp4", "mov", "avi"].includes(
                              item?.profilePicture
                                ?.split(".")
                                .splice(-1)[0]
                                .toLowerCase()
                            ) ? (
                              <>
                                <div className="card-img-wrap shadow-profile">
                                  <video
                                    src={item?.profilePicture}
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    style={{
                                      cursor: "pointer",
                                      width: "130px",
                                      height: "130px",
                                      objectFit: "cover",
                                      verticalAlign: "middle",
                                      borderRadius: "100%",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <source src={item?.profilePicture} />
                                  </video>
                                </div>
                              </>
                            ) : (
                              <div
                                onClick={() => {
                                  window.open(`/our-mates/${item.id}`);
                                }}
                                style={{ cursor: "pointer" }}
                                className="card-img-wrap shadow-profile"
                              >
                                <img
                                  className="img"
                                  src={
                                    item?.profilePicture ||
                                    "/static/dummyimg.svg"
                                  }
                                  alt=""
                                />
                              </div>
                            )}

                            <div
                              onClick={() => {
                                window.open(`/our-mates/${item.id}`);
                              }}
                              className="username"
                              style={{ color: "#25272D", cursor: "pointer" }}
                            >
                              {item.name}
                            </div>
                            {item?.distance ? (
                              <div
                                className="location-info"
                                style={{ color: "#6C6D6F" }}
                              >
                                📍 {item?.distance?.toFixed(2)} km’s away...
                              </div>
                            ) : null}

                            {item?.interests && item?.interests.length > 0 && (
                              <div className="people-item">
                                <div
                                  className="row "
                                  style={{
                                    flexWrap: "wrap",
                                    gap: 5,
                                    marginTop: 6,
                                    paddingLeft: 10,
                                  }}
                                >
                                  {item?.interests.map((it) => (
                                    <div className="people-pill">
                                      {it.title}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            <div className="button-box">
                              <div
                                className="like-btn"
                                onClick={() => {
                                  if (!getItemFromStore("userProfile")) {
                                    showToast(
                                      "Please log in to mark as favorite.",
                                      "error"
                                    );
                                  } else {
                                    if (item?.isMarkedFavourite) {
                                      removeActivity(
                                        item.id,
                                        getItemFromStore("userProfile")
                                          .userType === USER_TYPE.MEMBER &&
                                          getItemFromStore("userProfile")
                                            .isTrainer
                                          ? getItemFromStore("userProfile")
                                              ?.user
                                          : getItemFromStore("userProfile")?.id,
                                        endpoints.favourite
                                      );
                                    } else {
                                      AddActivity(
                                        item.id,
                                        getItemFromStore("userProfile")
                                          .userType === USER_TYPE.MEMBER &&
                                          getItemFromStore("userProfile")
                                            .isTrainer
                                          ? getItemFromStore("userProfile")
                                              ?.user
                                          : getItemFromStore("userProfile")?.id,
                                        endpoints.favourite
                                      );
                                    }
                                  }
                                }}
                                style={{
                                  border: item?.isMarkedFavourite
                                    ? "1px solid rgba(0, 0, 0, 0.32)"
                                    : "none",
                                  position: "initial",
                                }}
                              >
                                {item?.isMarkedFavourite ? (
                                  <img
                                    src="/static/listing/heartA.svg"
                                    style={{ cursor: "pointer" }}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/static/listing/heart.svg"
                                    style={{ cursor: "pointer" }}
                                    alt=""
                                  />
                                )}
                              </div>
                              {/* <Button
                                    btnTxt="Profile"
                                    className={"backtologin profile"}
                                    btnImg={"/static/profile-circle-btn.svg"}
                                    onClick={() => {
                                      navigate(`/our-mates/${id}`);
                                    }}
                                  /> */}
                              <Button
                                disabled={
                                  item?.connectionStatus?.status === "Pending"
                                }
                                btnTxt={
                                  item?.connectionStatus?.status === "Pending"
                                    ? "Request sent"
                                    : "Connect"
                                }
                                className={
                                  item?.connectionStatus?.status === "Pending"
                                    ? "disabled-connect-s"
                                    : "backtologin connect-s"
                                }
                                btnImg={
                                  item?.connectionStatus?.status !== "Pending"
                                    ? "/static/person-add-btn.svg"
                                    : ""
                                }
                                onClick={() => {
                                  if (!getItemFromStore("userProfile")) {
                                    navigate(ROUTE_CONSTANTS.register)
                                  } else {
                                    connectUser(item.id);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!showMap && activeTab === "tab1" && (
          <div className="pages-container my-3">
            <div className="pages-inner-container">
              <div className="pagination-box">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <div className="previous-button">
                        {" "}
                        <img
                          src="/static/u_angle-left-b(1).svg"
                          alt=""
                        /> Next{" "}
                      </div>
                    </>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  forcePage={currentPage}
                  pageCount={Math.ceil(pageCount / limit)}
                  previousLabel={
                    <>
                      <div className="previous-button">
                        Previous <img src="/static/u_angle-left-b.svg" alt="" />
                      </div>
                    </>
                  }
                  renderOnZeroPageCount={null}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {!showMap && */}
      <GlobalFooter />
      {/* // } */}
      {showFilter && (
        <FilterPopup
          isLocationAvailable={currentLng}
          setCurrentPage={setCurrentPage}
          setAddress={setAddress}
          setLat={setLat}
          setLong={setLong}
          address={address}
          setApplyCount={() => {
            let temp_apply = applyCount;
            setApplyCount(temp_apply + 1);
          }}
          setInterests={setInterests}
          setEventType={setEventType}
          eventType={eventType}
          show={showFilter}
          onHide={() => setShowFilter(false)}
          setIsFilterActive={setIsFilterActive}
          eventFilterParams={eventFilterParams}
          setEventFilterParams={setEventFilterParams}
        />
      )}
    </>
  );
};

export default EventsSearch;
