import React, { Fragment } from "react";
import { Box } from "@mui/material";
import MeetingLinkFields from "../MeetingLinkFields";
import "./style.scss";
import { FORM_KEYS } from "../../../../constants/helperScheduleDates";

type Props = {
  watch: any;
  setValue: any;
  getValues: any;
};

const MeetLinkContainer = ({ setValue, getValues, watch }: Props) => {
  return (
    <Box>
      <Box className="meet_link_inner_wrapper">
        {watch(FORM_KEYS.DATE_TIME_SLOTS) &&
          watch(FORM_KEYS.DATE_TIME_SLOTS)?.map((item, index) => (
            <Fragment key={item?.date + index}>
              <MeetingLinkFields
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                data={item}
                indexing={index}
              />
            </Fragment>
          ))}
      </Box>
    </Box>
  );
};

export default MeetLinkContainer;
